exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-competitions-js": () => import("./../../../src/pages/competitions.js" /* webpackChunkName: "component---src-pages-competitions-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-competitions-js": () => import("./../../../src/pages/en/competitions.js" /* webpackChunkName: "component---src-pages-en-competitions-js" */),
  "component---src-pages-en-download-js": () => import("./../../../src/pages/en/download.js" /* webpackChunkName: "component---src-pages-en-download-js" */),
  "component---src-pages-en-events-js": () => import("./../../../src/pages/en/events.js" /* webpackChunkName: "component---src-pages-en-events-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-live-js": () => import("./../../../src/pages/en/live.js" /* webpackChunkName: "component---src-pages-en-live-js" */),
  "component---src-pages-en-messages-js": () => import("./../../../src/pages/en/messages.js" /* webpackChunkName: "component---src-pages-en-messages-js" */),
  "component---src-pages-en-movies-js": () => import("./../../../src/pages/en/movies.js" /* webpackChunkName: "component---src-pages-en-movies-js" */),
  "component---src-pages-en-nationalskills-js": () => import("./../../../src/pages/en/nationalskills.js" /* webpackChunkName: "component---src-pages-en-nationalskills-js" */),
  "component---src-pages-en-news-js": () => import("./../../../src/pages/en/news.js" /* webpackChunkName: "component---src-pages-en-news-js" */),
  "component---src-pages-en-osos-js": () => import("./../../../src/pages/en/osos.js" /* webpackChunkName: "component---src-pages-en-osos-js" */),
  "component---src-pages-en-skillgrandprix-js": () => import("./../../../src/pages/en/skillgrandprix.js" /* webpackChunkName: "component---src-pages-en-skillgrandprix-js" */),
  "component---src-pages-en-stories-js": () => import("./../../../src/pages/en/stories.js" /* webpackChunkName: "component---src-pages-en-stories-js" */),
  "component---src-pages-en-worldskills-js": () => import("./../../../src/pages/en/worldskills.js" /* webpackChunkName: "component---src-pages-en-worldskills-js" */),
  "component---src-pages-en-youthskills-js": () => import("./../../../src/pages/en/youthskills.js" /* webpackChunkName: "component---src-pages-en-youthskills-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-alllive-1120-js": () => import("./../../../src/pages/live/alllive1120.js" /* webpackChunkName: "component---src-pages-live-alllive-1120-js" */),
  "component---src-pages-live-alllive-2130-js": () => import("./../../../src/pages/live/alllive2130.js" /* webpackChunkName: "component---src-pages-live-alllive-2130-js" */),
  "component---src-pages-live-alllive-3141-js": () => import("./../../../src/pages/live/alllive3141.js" /* webpackChunkName: "component---src-pages-live-alllive-3141-js" */),
  "component---src-pages-live-alllive-js": () => import("./../../../src/pages/live/alllive.js" /* webpackChunkName: "component---src-pages-live-alllive-js" */),
  "component---src-pages-live-broadcast-js": () => import("./../../../src/pages/live/broadcast.js" /* webpackChunkName: "component---src-pages-live-broadcast-js" */),
  "component---src-pages-live-index-js": () => import("./../../../src/pages/live/index.js" /* webpackChunkName: "component---src-pages-live-index-js" */),
  "component---src-pages-live-opening-js": () => import("./../../../src/pages/live/opening.js" /* webpackChunkName: "component---src-pages-live-opening-js" */),
  "component---src-pages-live-skillsintroduction-js": () => import("./../../../src/pages/live/skillsintroduction.js" /* webpackChunkName: "component---src-pages-live-skillsintroduction-js" */),
  "component---src-pages-live-ws-2024-index-js": () => import("./../../../src/pages/live/ws2024/index.js" /* webpackChunkName: "component---src-pages-live-ws-2024-index-js" */),
  "component---src-pages-live-ys-2024-01-js": () => import("./../../../src/pages/live/ys2024/01.js" /* webpackChunkName: "component---src-pages-live-ys-2024-01-js" */),
  "component---src-pages-live-ys-2024-02-js": () => import("./../../../src/pages/live/ys2024/02.js" /* webpackChunkName: "component---src-pages-live-ys-2024-02-js" */),
  "component---src-pages-live-ys-2024-03-js": () => import("./../../../src/pages/live/ys2024/03.js" /* webpackChunkName: "component---src-pages-live-ys-2024-03-js" */),
  "component---src-pages-live-ys-2024-04-js": () => import("./../../../src/pages/live/ys2024/04.js" /* webpackChunkName: "component---src-pages-live-ys-2024-04-js" */),
  "component---src-pages-live-ys-2024-05-js": () => import("./../../../src/pages/live/ys2024/05.js" /* webpackChunkName: "component---src-pages-live-ys-2024-05-js" */),
  "component---src-pages-live-ys-2024-06-js": () => import("./../../../src/pages/live/ys2024/06.js" /* webpackChunkName: "component---src-pages-live-ys-2024-06-js" */),
  "component---src-pages-live-ys-2024-07-js": () => import("./../../../src/pages/live/ys2024/07.js" /* webpackChunkName: "component---src-pages-live-ys-2024-07-js" */),
  "component---src-pages-live-ys-2024-08-js": () => import("./../../../src/pages/live/ys2024/08.js" /* webpackChunkName: "component---src-pages-live-ys-2024-08-js" */),
  "component---src-pages-live-ys-2024-09-js": () => import("./../../../src/pages/live/ys2024/09.js" /* webpackChunkName: "component---src-pages-live-ys-2024-09-js" */),
  "component---src-pages-live-ys-2024-10-js": () => import("./../../../src/pages/live/ys2024/10.js" /* webpackChunkName: "component---src-pages-live-ys-2024-10-js" */),
  "component---src-pages-live-ys-2024-11-js": () => import("./../../../src/pages/live/ys2024/11.js" /* webpackChunkName: "component---src-pages-live-ys-2024-11-js" */),
  "component---src-pages-live-ys-2024-12-js": () => import("./../../../src/pages/live/ys2024/12.js" /* webpackChunkName: "component---src-pages-live-ys-2024-12-js" */),
  "component---src-pages-live-ys-2024-13-js": () => import("./../../../src/pages/live/ys2024/13.js" /* webpackChunkName: "component---src-pages-live-ys-2024-13-js" */),
  "component---src-pages-live-ys-2024-14-js": () => import("./../../../src/pages/live/ys2024/14.js" /* webpackChunkName: "component---src-pages-live-ys-2024-14-js" */),
  "component---src-pages-live-ys-2024-15-js": () => import("./../../../src/pages/live/ys2024/15.js" /* webpackChunkName: "component---src-pages-live-ys-2024-15-js" */),
  "component---src-pages-live-ys-2024-closing-js": () => import("./../../../src/pages/live/ys2024/closing.js" /* webpackChunkName: "component---src-pages-live-ys-2024-closing-js" */),
  "component---src-pages-live-ys-2024-index-js": () => import("./../../../src/pages/live/ys2024/index.js" /* webpackChunkName: "component---src-pages-live-ys-2024-index-js" */),
  "component---src-pages-live-ys-2024-oped-js": () => import("./../../../src/pages/live/ys2024/oped.js" /* webpackChunkName: "component---src-pages-live-ys-2024-oped-js" */),
  "component---src-pages-lyon-js": () => import("./../../../src/pages/lyon.js" /* webpackChunkName: "component---src-pages-lyon-js" */),
  "component---src-pages-messages-js": () => import("./../../../src/pages/messages.js" /* webpackChunkName: "component---src-pages-messages-js" */),
  "component---src-pages-movies-js": () => import("./../../../src/pages/movies.js" /* webpackChunkName: "component---src-pages-movies-js" */),
  "component---src-pages-nationalskills-js": () => import("./../../../src/pages/nationalskills.js" /* webpackChunkName: "component---src-pages-nationalskills-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-osos-js": () => import("./../../../src/pages/osos.js" /* webpackChunkName: "component---src-pages-osos-js" */),
  "component---src-pages-skillgrandprix-js": () => import("./../../../src/pages/skillgrandprix.js" /* webpackChunkName: "component---src-pages-skillgrandprix-js" */),
  "component---src-pages-skills-js": () => import("./../../../src/pages/skills.js" /* webpackChunkName: "component---src-pages-skills-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-worldskills-js": () => import("./../../../src/pages/worldskills.js" /* webpackChunkName: "component---src-pages-worldskills-js" */),
  "component---src-pages-youthskills-js": () => import("./../../../src/pages/youthskills.js" /* webpackChunkName: "component---src-pages-youthskills-js" */)
}

